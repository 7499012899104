import React from 'react'
import Img from 'gatsby-image'
import { Container } from "reactstrap"

import companyThumbnail from "../images/chappi/logo.png"
import heroBild from "../images/chappi/09.jpg"



const HeroImpressum = ({
    title="Impressum",
    // subtitle="Qualitätskaffee aus Vietnam",
    image,
    fullScreen=false
}) => {
    console.log(image)

  return (
    <div
      className="hero"
      id="hero"
      style={{
        backgroundPosition:'center',
        backgroundSize:'contain',
        backgroundRepeat:'no-repeat',
        background: `linear-gradient( rgba(0, 0, 0, 0.11), rgba(0, 0, 0, 0.11) ), 
        url(${heroBild})`, height: fullScreen ? '100vh' : '60vh' 
      }}
    >
      <section className="h-100 d-flex align-items-center text-center bg-dark-100">
        <Container>
        {/* <img
            src={companyThumbnail}
            alt="Company Thumbnail"
            className="img-head-home"
        />   */}
          <main className="hero-content">
          <h1 className="title">{title}</h1>
            

          {/* <p className="hero-subber">{subtitle}</p> */}
          </main>
        </Container>
      </section>
    </div>
  )
}

export default HeroImpressum
