import React from "react"

import Layout2 from "../components/layout2"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
// import LeafletMap from '../components/leafletMap'
import Footer from '../components/footer'
import HeroImpressum from '../components/hero-impressum'


const ImpressumFr = ({data: {
  
header} }) => {
return (
  <Layout2>
    <SEO title="Home" />
    
    <div style={{backgroundColor:'#b17551'}}>
    <HeroImpressum />



    <section >
      {/* <span className="description"> */}
      {/* <h1 className="sub-titel">
      Impressum
      </h1> */}
      
     
          </section>
          <section className="container ">
          <div>
        <strong>Responsable du contenu</strong><br></br>
        <p>Daisy International Switzerland GmbH <br></br>
        Joignable à l'adresse <a href="mailto:hello@chappicoffee.ch">hello@chappicoffee.ch</a>

        </p>
        <br></br>
        {/* <strong>Webseite erstellt von</strong>
        <br></br>
        <span><a href="https://block-lab.ch">Block Lab GmbH</a></span> */}
         {/* <br></br><br></br>
         <strong>Fotografie</strong>
        <br></br>
        <span>
          Boris Adolf Photography
         
          </span> */}
         <br></br><br></br>

      </div>

     
      <div>
     

        <h3 className="subber-titel">Protection des données</h3>
        <p>

       <strong>
       E-mails et formulaires d'inscription
         </strong>  <br/>
         Ce site web offre aux utilisateurs une adresse e-mail et des formulaires d'inscription afin de permettre une communication directe avec Daisy International Switzerland GmbH et l'inscription à ses offres. Lorsque vous prenez contact avec Daisy International Switzerland GmbH, les données personnelles que vous nous transmettez sont enregistrées. Ces données ne sont collectées et enreg

</p> 
        <br></br><br></br>
        <strong>
        Remarques sur le traitement des données en relation avec Google Analytics

        </strong>
        <p>
        Ce site web utilise Google Analytics, un service d'analyse web de Google Ireland Limited. Si le responsable du traitement des données sur ce site web est situé en dehors de l'Espace économique européen ou de la Suisse, le traitement des données Google Analytics est effectué par Google LLC. Google LLC et Google Ireland Limited sont ci-après dénommés "Google".
                  <br></br><br></br>
                  Google Analytics utilise des "cookies", des fichiers texte qui sont enregistrés sur l'ordinateur du visiteur du site et qui permettent d'analyser l'utilisation du site par le visiteur du site. Les informations générées par le cookie sur l'utilisation de ce site web par le visiteur de la page (y compris l'adresse IP abrégée) sont généralement transmises à un serveur de Google et y sont enregistrées.
                  
                            <br></br><br></br>
                            Google Analytics est utilisé exclusivement avec l'extension "_anonymizeIp()" sur ce site web. Cette extension assure l'anonymisation de l'adresse IP en la raccourcissant et exclut tout lien direct avec une personne. Grâce à cette extension, l'adresse IP est préalablement abrégée par Google dans les États membres de l'Union européenne ou dans d'autres États signataires de l'accord sur l'Espace économique européen. Ce n'est que dans des cas exceptionnels que l'adresse IP complète est transmise à un serveur de Google aux États-Unis et y est abrégée. L'adresse IP transmise par le navigateur concerné dans le cadre de Google Analytics n'est pas recoupée avec d'autres données de Google.
                            
                                      <br></br><br></br>
                                      Google utilisera les informations recueillies pour le compte de l'exploitant du site afin d'évaluer l'utilisation du site, de compiler des rapports sur l'activité du site et de fournir à l'exploitant du site d'autres services liés à l'activité du site et à l'utilisation d'Internet (article 6, paragraphe 1, point f du RGPD). L'intérêt légitime du traitement des données réside dans l'optimisation de ce site web, l'analyse de l'utilisation du site web et l'adaptation des contenus. Les intérêts des utilisateurs sont suffisamment protégés par la pseudonymisation.
                                      
                                                <br></br><br></br>
                                                Google LLC. offre une garantie de respect d'un niveau de protection des données adéquat sur la base des clauses contractuelles standard. Les données envoyées et associées à des cookies, des identifiants d'utilisateur (par ex. User-ID) ou des identifiants publicitaires sont automatiquement effacées après 50 mois.
                                                
                                                          <br></br><br></br>
          
        </p>
      </div> 
    </section>

    <Footer/>
    </div>

</Layout2> 

)

}

// export const fluidImage = graphql`
//   fragment fluidImage on File {
//     childImageSharp {
//       fluid(maxWidth: 1600) {
//         ...GatsbyImageSharpFluid
//       }
//     }
//   }
// `;

export const pageQuery = graphql`
  query {
    header: file(relativePath: { eq: "AD_7127_rgb.jpg" }) {
      ...fluidImage
    }
    coffeePortrait: file(relativePath: { eq: "Flugaufnahme-web.jpg" }) {
      ...fluidImage
    }
    latte: file(relativePath: { eq: "land3.jpg" }) {
      ...fluidImage
    }
    coffeeBags: file(relativePath: { eq: "kids_haas_web.jpg" }) {
      ...fluidImage
    }
  }
`;

export default ImpressumFr
